@import url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");
@import 'antd/dist/antd.css';

html, body, #root {
  font-family:'NanumSquare', sans-serif !important;
}

body {
  background-image:linear-gradient(#8dc63f,#2daf4f);
  background-repeat:no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment:fixed;
}
#root {
  background-color: rgba( 255, 255, 255, 0 );
}

/* const width */
.w-unset { width: unset !important; }
.w-100 { width: 100%; }
.w-110 { width: 110px !important; }
.w-100-40 { width: calc(100% - 40px) !important; }

/* const background */
.bc-dark { background-color: #1f1f1f; }

/* const border */
.br-2 { border-radius: 2rem !important; }
.btrr-2 { border-top-right-radius: 2rem !important; }
.bbrr-2 { border-bottom-right-radius: 2rem !important; }
.btlr-2 { border-top-left-radius: 2rem !important; }
.bblr-2 { border-bottom-left-radius: 2rem !important; }
.bb-dot-green { border-bottom: 1px dotted #00d06f }

/* const display */
.d-inline-flex { display: inline-flex; }

/* const position */
.p-relative { position: relative; }
.p-absolute { position: absolute !important; }
.r-0 { right: 0; }
.r-8 { right: 8px; }
.r-10 { right: 10px; }
.r-20 { right: 20px; }
.l-8 { left: 8px; }
.l-10 { left: 10px; }

/* const cursor */
.pointer { cursor: pointer; }

/* const text-align */
.ta-center { text-align: center; }

/* const font */
.fs-10 { font-size: 10px; }
.fs-11 { font-size: 11px; }
.fs-12 { font-size: 12px; }
.fs-13 { font-size: 13px !important; }
.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.fs-18 { font-size: 18px; }
.fs-20 { font-size: 20px; }
.bold { font-weight: bold; }
.c-lightgray { color: rgba(0, 0, 0, 0.25) !important;}
.c-white { color: #fff; }
.c-gray { color: #687374; }
.c-darkgray { color: #595959; }
.c-green { color: #00af89 !important; }
.c-black { color: #000000; }
.c-blue { color: #00b9f2 !important; }
.hover-blue:hover { color: #40a9ff; }
.ws-p { white-space: pre; }
.ws-pl { white-space: pre-line; }
.wb-ka { word-break: keep-all; }
.underline { text-decoration: underline; }

/* const padding */
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-45 { padding-top: 45px; }
.pt-70 { padding-top: 70px; }
.pb-70 { padding-bottom: 70px; }
.pl-10 { padding-left: 10px !important; }

/* const margin */
.m-auto { margin: auto; }
.m-0 { margin: 0px; }
.m-10 { margin: 10px; }
.mt-0 { margin-top: 0px; }
.mt-8 { margin-top: 8px; }
.mt-10 { margin-top: 10px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-45 { margin-top: 45px; }
.mb-0 { margin-bottom: 0 !important; }
.mb-8 { margin-bottom: 8px; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px; }
.mb-30 { margin-bottom: 30px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }
.mr-35 { margin-right: 35px; }
.ml-3 { margin-left: 3px; }
.ml-5 { margin-left: 5px; }
.ml-12 { margin-left: 12px; }
.ml-14 { margin-left: 14px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }
.ml-35 { margin-left: 35px; }

/* animation */
.shake {
  transform-origin: bottom;
  animation: shake 2.5s infinite linear;
}
@keyframes shake {
  0%, 50% {
    transform: rotate(0deg);
	}
  5%, 15%, 25%, 35%, 45% {
    transform: rotate(13deg);
  }
  10%, 20%, 30%, 40% {
    transform: rotate(-13deg);
  }
}

/* button */
.basic-button {
  display: block !important;
  width: 100%;
  height: 50px;
  font-size: 18px !important;
  font-weight: bold !important;
  color: #fff !important;
}
  .basic-button.w-0 {
    width: unset;
  }
  .basic-button:disabled {
    background-color: #c6c6c6 !important;
    border-color: #c6c6c6 !important;
  }
  .basic-button.gray,
  .basic-button.gray:focus {
    background-color: #c6c6c6;
    border-color: #c6c6c6;
  }
    .basic-button.gray:hover {
      background-color: #d4d4d4;
      border-color: #d4d4d4;
    }
  .basic-button.light-green,
  .basic-button.light-green:focus {
    background-image: linear-gradient(#82d018, #54e279);
    border-color: rgba(125,230,126,0.26);
  }
    .basic-button.light-green:hover,
    .basic-button.light-green:focus {
      background-image: linear-gradient(#82d018, #54e279);
      border-color: rgba(125,230,126,0.26);
    }
  .basic-button.dark-green,
  .basic-button.dark-green:focus {
    background-image: linear-gradient(to right, #00d06f, #00c99b);
    border-color: rgba(125,230,126,0.26);
  }
    .basic-button.dark-green:hover,
    .basic-button.dark-green:focus {
      background-image: linear-gradient(to right, #00d06f, #00c99b);
      border-color: rgba(125,230,126,0.26);
    }
  .basic-button.green,
  .basic-button.green:focus {
    background-color: #00af89;
    border-color: #00af89;
  }
    .basic-button.green:hover,
    .basic-button.green:focus {
      background-color: #1ebd95;
      border-color: #1ebd95;
    }
  .basic-button.light-blue,
  .basic-button.light-blue:focus {
    background-image: linear-gradient(#1890ff, #00b9f2);
    border-color: rgba(16,35,158,0.26);
  }
    .basic-button.light-blue:hover,
    .basic-button.light-blue:focus {
      background-image: linear-gradient(#1890ff, #00b9f2);
      border-color: rgba(16,35,158,0.26);
    }
  .basic-button.blue,
  .basic-button.blue:focus {
    background-color: #00b9f2;
    border-color: #00b9f2;
  }
    .basic-button.blue:hover {
      background-color: #29d4ff;
      border-color: #29d4ff;
    }
.fix-refresh-button {
  width: 50px;
  height: 50px;
  padding: 0 10px !important;
  background-color: #434343 !important;
  border-radius: 2rem !important;
  border-color: #434343 !important;
  font-size: 22px !important;
}
  .fix-refresh-button > span {
    line-height: 0 !important;
  }

/* alert */
.alert-success .ant-message-notice-content {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.alert-fail .ant-message-notice-content {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

/* modal */
.modal-location-image svg {
  width: 80px !important;
  height: 80px;
  color: #00b9f2;
}
.ant-modal-footer {
  display: inline-flex;
  width: 100%;
  padding: 0;
}

/* step */
.ant-steps-item-finish.step-wrap .ant-steps-item-content svg,
.ant-steps-item-process.step-wrap .ant-steps-item-content svg {
  color: #025070;
}
.ant-steps-item-finish.step-wrap .ant-steps-item-icon {
  border-color: #025070;
}
.ant-steps-item-finish.step-wrap .ant-steps-item-icon > .ant-steps-icon {
  color: #025070;
}
.ant-steps-item-finish.step-wrap > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #025070;
}
.ant-steps-item-process.step-wrap .ant-steps-item-icon {
  background: #025070;
  border-color: #025070;
}
.step-wrap .ant-steps-item-tail {
  margin-left: 18px !important;
  padding: 3.5px 18px !important;
}
.step-wrap .ant-steps-item-icon {
  margin-left: 6px !important;
}
.step-wrap .ant-steps-item-content {
  width: 37px !important;
  margin-top: 0 !important;
  text-align: center !important;
}

/* tooltip */
.ant-tooltip {
  z-index: 0 !important;
}
  .ant-tooltip .basic-button {
    font-size: 13px !important;
  }

/* notification */
.ant-notification {
  z-index: 0 !important;
}

/* input box */
.basic-input {
  height: 50px;
}
  .basic-input input {
    font-size: 18px;
  }
    .basic-input.has-suffix .ant-input-suffix{
      font-size: 18px;
      color: #f5222d;
    }
  .ant-row.ant-form-item {
    margin-bottom: 18px;
  }
    .ant-row.ant-form-item.ant-form-item-has-success {
      margin-bottom: 18px;
    }
    .ant-row.ant-form-item.ant-form-item-with-help {
      margin-bottom: 0 !important;
    }
    .ant-row.ant-form-item .ant-form-item-explain {
      min-height: 18px !important;
      font-size: 12px !important
    }
  
  /* form card style */
.form-card-wrap {
  margin-bottom: 15px;
  border: 1px solid #cdcdcd;
}
.card {
  background-color: rgba(245,245,245);
  padding: 20px 0 20px !important;
}
  .card .ant-row.ant-form-item {
    margin: 0 20px;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help .ant-form-item-explain {
    margin-top: 3px;
    font-size: 14px !important;
    color: #08979c;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help.inline-flex .ant-form-item-control-input-content {
    display: inline-flex;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help.inline-flex input {
    font-size: 14px;
  }
  .card .ant-row.ant-form-item .ant-col.ant-form-item-label label {
    color: #8a8a8a;
    font-size: 16px;
    font-weight: bold;
  }
    .card .ant-row.ant-form-item .ant-col.ant-form-item-label .anticon.anticon-question-circle.ant-form-item-tooltip {
      margin-left: 2px;
      font-size: 16px;
    }

/* form item */
.ant-form .ant-form-item .ant-form-item-label {
  flex: unset !important;
  padding: 0;
  text-align: right;
  white-space: nowrap;
}
.ant-form .ant-form-item .ant-form-item-control {
  flex: unset !important;
}

/* form inline(label and input) item */
.inline-label-input {
  margin-top: 20px;
}
  .inline-label-input label {
    margin-top: 3px !important;
  }
  .inline-label-input .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .inline-label-input .inline-item .ant-form-item-control {
    margin-left: 20px;
    width: calc(100% - 70px);
  }
  @media (min-width: 575px) {
    .inline-label-input .inline-item .ant-form-item-control {
      margin-left: 20px;
      width: calc(100% - 80px);
    }
  }
  .inline-label-input .inline-item .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    color: #40a9ff;
  }

/* casacader */
.ant-cascader-menus {
  min-height: 250px;
  z-index: 1000;
}
  .ant-cascader-menus ul {
    min-height: 250px;
  }
  @media (min-height: 650px) {
    .ant-cascader-menus ul {
      min-height: 330px;
    }
  }
  @media (min-height: 760px) {
    .ant-cascader-menus ul {
      min-height: 430px;
    }
  }

/* no list data */
.no-list {
  text-align: center;
  margin: 50px 0 30px;
}
  .no-list svg {
    width: 80px !important;
    height: 80px;
    color: #fff;
  }

/* layout */
.layout-wrapper {
  min-height: 100vh;
  background-color: rgba( 255, 255, 255, 0 ) !important;
}

/* header */
.header-wrapper {
  display: inline-flex;
  position: fixed;
  width: 100%;
  background-image: linear-gradient(#9dde23, #82d000) !important;
  padding: 0 20px !important;
  z-index: 1;
}
  .header-wrapper .header-logo {
    width: 32px;
    height: 32px;
    margin: 16px 16px 16px 0;
  }
  .header-wrapper .header-title {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
  }
  .header-wrapper .menu-bar {
    position: absolute;
    right: 20px;
    padding-top: 2px;
    height: 62px;
    cursor: pointer;
  }
    .header-wrapper .menu-bar svg {
      color: #fff;
      width: 20px;
      height: 20px;
    }

/* sidebar */
.ant-drawer-close {
  font-size: 18px;
}

/* content */
.content-wrapper {
  /* background: #fff; */
  background: unset !important;
}
  .content-wrapper .content-body {
    min-height: 600px;
    padding: 64px 20px 42px;
    /* z-index: 0 !important; */
  }
  .content-wrapper .content-body .content-main-logo {
    width: 180px;
    height: 180px;
  }

/* footer */
.footer-wrapper {
  width: 100%;
  line-height: 1.6em;
  background-color: rgb(0, 165, 32);
  padding: 25px !important;
  text-align: left;
  font-size: 0.8em !important;
  color: #fff;
}